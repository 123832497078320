<template>
    <div class="ticket-info">
        <div v-if="!status">
            Загрузка...
        </div>
        <div v-else>
            <h4>Статус по заявке {{ ticket }}</h4>
            <p><span class="ticket-info__status">{{ message }}</span></p>
            <p v-if="status === 'attention'">
                Свяжитесь с нами по телефону
                <a :href="`tel:${phoneLink}`">
                    {{ phone }}
                </a>
            </p>
            <div class="link">
                <base-button mode="black" link to="/unsubscribe">
                    Вернуться
                </base-button>
            </div>
        </div>
    </div>
</template>

<script>
import getTicket from '@/api/ticket'
import contacts from '@/mixins/contacts';

import './ticket-info.scss';

export default {
    mixins: [
        contacts,
    ],
    data() {
        return {
            status: '',
            ticket: ''
        }
    },
    async mounted() {
        const data = await getTicket(this.$route.params.uid);

        this.ticket = data.ticket
        this.status = data.status
    },
    computed: {
        message() {
            switch (this.status) {
                case 'success':
                    return 'Подписка отменена';
                case 'attention':
                    return 'Требует уточнений';
                case 'notFound':
                    return 'Не найдено';
                default:
                    return 'В работе'
            }
        }
    }
}
</script>